import React from 'react'
import EmailMe from '../EmailMe'
import { Wrapper, Nav, NavItem, NavLink } from './style'

const Menu = () =>
  <Wrapper>
    <Nav>
      <NavItem>
        <a href="https://twitter.com/VardanSawhney" rel="noopener noreferrer" target="_blank">Twitter</a>
      </NavItem>
      <NavItem>
        <a href="https://medium.com/@vardan_sawhney" rel="noopener noreferrer" target="_blank">Medium</a>
      </NavItem>
      <NavItem>
        <a href="https://www.linkedin.com/in/vardan-s/" rel="noopener noreferrer" target="_blank">LinkedIn</a>
      </NavItem>
      <NavItem>
        <a href="https://github.com/commai" rel="noopener noreferrer" target="_blank">Github</a>
      </NavItem>
      <NavItem>
        <a href="https://drive.google.com/file/d/1bRo8a4RRbyqeCGff-RaI3OJ1IAsEGrlh/view?usp=sharing" rel="noopener noreferrer" target="_blank">Résumé</a>
      </NavItem>
    </Nav>
    <Nav>
      <NavItem>
        <NavLink to='/works'>Projects</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to='/profile'>Profile</NavLink>
      </NavItem>
      <NavItem highlight><EmailMe text="Contact"/>
    </NavItem>
    </Nav>
  </Wrapper>

export default Menu

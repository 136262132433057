export const LAMBDA_ENDPOINT = 'https://kvz60gz535.execute-api.us-east-1.amazonaws.com/prod/distance'
export const IS_PROD = process.env.NODE_ENV === 'production'
export const META = {
  common: {
    image: '/images/Profile/vardan.jpg',
  },
  index: {
    title: 'Vardan Sawhney | Interdisciplinary Programmer',
    description: 'Vardan Sawhney, interdisciplinary programmer based in Toronto, Canada. I focus primarily on writing scalable tools.'
  },
  profile: {
    title: 'Vardan Sawhney | Profile',
    description: 'Over the last 6+ years, I have been working with small and medium-size companies around Toronto. I am currently looking to expand into the realm of startups.'
  },
  works: {
    title: 'Vardan Sawhney | Works',
    description: 'A selection of the work carried out over 6+ years of work. Among which: Websites, Web applications, Apps, tools and more.'
  }
}
